<template>
  <div class="container">
    <div class="filter_nav">
      <div class="classify">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/allCourses' }"
            >全部课程</el-breadcrumb-item
          >
          <el-breadcrumb-item
            v-if="mt.length > 0"
            :to="{
              path: '/allCourses',
              query: { mt: $route.query.mt, title: $route.query.title },
            }"
            >{{ mt[0].name }}</el-breadcrumb-item
          >
          <el-breadcrumb-item v-if="st.length > 0">{{
            st[0].name
          }}</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="category">
          <div class="top_category">
            <span
              class="category_desc"
              v-show="!$route.query.mt && !$route.query.st"
              >全部课程：</span
            >
            <span
              class="category_desc"
              v-show="$route.query.mt && !$route.query.st"
              >学习方向：</span
            >
            <span class="category_desc" v-show="$route.query.st">分类：</span>
            <div class="all_tags">
              <span
                class="tag"
                :class="{ active: !$route.query.tt }"
                @click="selAll"
                >全部</span
              >
              <span
                class="tag"
                :class="{ active: $route.query.tt == tag.categoryId }"
                v-for="tag in current"
                :key="tag.categoryId"
                @click="handleClick(tag)"
                >{{ tag.name }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="filter_bar">
        <div class="sorting">
          <span
            class="condition"
            :class="{
              active: params.orderByColumn == item.val,
              comprehensive: item.val == 'createTime',
            }"
            v-for="item in sorting"
            :key="item.val"
            @click="changeSorting(item.val)"
            >{{ item.name
            }}<i v-show="item.val != 'createTime'" class="iconfont"
              >&#xe61a;</i
            ></span
          >
        </div>
        <div class="filter">
          <el-radio-group v-model="params.free" @change="getCourseList">
            <el-radio>全部</el-radio>
            <el-radio :label="false">付费课</el-radio>
            <el-radio :label="true">免费课</el-radio>
          </el-radio-group>
        </div>
      </div>
    </div>
    <div class="main">
      <div class="lesson_box" v-if="!empty" v-loading="isLoading">
        <one-lesson
          class="one_lesson"
          v-for="lesson in courseList"
          :key="lesson.courseId"
          :lessonInfo="lesson"
          @click.native="goDetail(lesson.courseId)"
        />

        <div class="foot_page">
          <el-pagination
            background
            hide-on-single-page
            layout="prev, pager, next, jumper"
            :page-size="params.pageSize"
            :current-page.sync="params.pageNum"
            :total="total"
            @current-change="pageChange"
          ></el-pagination>
        </div>
      </div>
      <!-- 空状态 -->
      <empty-status v-else />
    </div>
  </div>
</template>

<script>
import emptyStatus from "@/components/emptyStatus/index";
import oneLesson from "@/components/oneLesson/index";
import { getCourses } from "@/api/curriculum";

export default {
  name: "allCourse",
  components: {
    oneLesson,
    emptyStatus,
  },
  created() {
    let id =
      this.$route.query.tt ||
      this.$route.query.st ||
      this.$route.query.mt ||
      null;
    this.updataCategoryId(id, this.$route.query.title);
  },
  watch: {
    $route: {
      handler: function () {
        let id =
          this.$route.query.tt ||
          this.$route.query.st ||
          this.$route.query.mt ||
          null;
        this.updataCategoryId(id, this.$route.query.title);
      },
      deep: true,
    },
  },
  computed: {
    categorys() {
      return this.$store.state.classificationOrgin;
    },
    // 三级分类
    current() {
      if (this.$route.query.st) {
        return this.categorys.filter((item) => {
          return item.parentId == this.$route.query.st;
        });
      } else if (this.$route.query.mt && !this.$route.query.st) {
        return this.categorys.filter((item) => {
          return item.parentId == this.$route.query.mt;
        });
      } else {
        return this.categorys.filter((item) => {
          return item.level == 1;
        });
      }
    },
    // 一级分类
    mt() {
      return this.categorys.filter((item) => {
        return item.categoryId == this.$route.query.mt;
      });
    },
    // 二级分类
    st() {
      return this.categorys.filter((item) => {
        return item.categoryId == this.$route.query.st;
      });
    },
  },
  methods: {
    // 点击导航标签
    handleClick(ctx) {
      // console.log(ctx);
      const query = JSON.parse(JSON.stringify(this.$route.query));
      if (ctx.level == 1) {
        query.mt = ctx.categoryId;
      } else if (ctx.level == 2) {
        query.st = ctx.categoryId;
      } else {
        query.tt = ctx.categoryId;
      }
      this.$router.push({ path: this.$route.path, query });
    },
    // 搜索当前全部
    selAll() {
      this.$router.push({
        path: this.$route.path,
        query: { mt: this.$route.query.mt, st: this.$route.query.st },
      });
    },
    // 获取课程列表
    getCourseList() {
      // this.isLoading = true;
      this.$loading.show();
      getCourses(this.params).then((res) => {
        this.$loading.hide();
        if (res.code == 200) {
          if (res.total <= 0) {
            this.empty = true;
          } else {
            this.empty = false;
          }
          this.total = res.total;
          this.courseList = res.rows;
        } else {
         this.$message.error(res.msg);
        }
      });
    },
    // 筛选
    changeSorting(val) {
      this.params.orderByColumn = val;
      this.getCourseList();
    },
    // 更新搜索id并拉取列表
    updataCategoryId(categoryId, title) {
      this.params.categoryId = categoryId;
      this.params.title = title;
      this.getCourseList();
    },
    // 翻页
    pageChange() {
      this.getCourseList();
    },
    // 课程详情
    goDetail(courseId) {
      let newUrl = this.$router.resolve({
        path: "/courseDetail",
        query: { courseId },
      });
      window.open(newUrl.href, "_blank");
    },
  },
  data() {
    return {
      courseList: [],
      params: {
        pageNum: 1,
        pageSize: 20,
        categoryId: null,
        title: "",
        isAsc: "desc",
        orderByColumn: "createTime",
      },
      total: 0,
      empty: false,
      isLoading: false,
      sorting: [
        { name: "综合排序", val: "createTime" },
        { name: "好评率", val: "starts" },
        { name: "按人气", val: "playCount" },
        { name: "按销量", val: "buyCount" },
        { name: "按收藏", val: "collectCount" },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.filter_nav {
  .classify {
    padding: 42px 0 18px;
    .category {
      margin-top: 30px;
      text-align: left;
      .category_desc {
        display: inline-block;
        margin-right: 15px;
        padding-top: 8px;
        font-size: 14px;
        color: #999;
        vertical-align: top;
      }
      .all_tags {
        display: inline-block;
        max-width: 1100px;
        .tag {
          display: inline-block;
          margin-bottom: 15px;
          height: 33px;
          line-height: 33px;
          padding: 0 20px;
          cursor: pointer;
          transition: color 0.3s ease;
          &:hover {
            color: #0d79ff;
          }
          &.active {
            color: #fff;
            background-color: #0d79ff;
            border-radius: 20px;
          }
        }
      }
    }
  }
  .filter_bar {
    margin-bottom: 25px;
    padding: 0 19px;
    height: 44px;
    line-height: 44px;
    background-color: #f6f6f6;
    overflow: hidden;
    .sorting {
      float: left;
      .condition {
        margin-right: 40px;
        cursor: pointer;
        &.comprehensive {
          margin-right: 53px;
        }
        &.active {
          color: #0d79ff;
          i {
            color: #0d79ff;
          }
        }
        i {
          color: #999;
          vertical-align: middle;
        }
      }
    }
    .filter {
      float: right;
      ::v-deep.el-radio-group {
        .el-radio__inner {
          border-radius: 0;
        }
        .is-checked {
          .el-radio__inner {
            border-color: #0d79ff;
            background: #0d79ff;
            &::after {
              content: "✔";
              width: 0;
              height: 0;
              font-size: 12px;
              color: #fff;
              left: 2px;
              top: 0;
              transition: none;
            }
          }
          .el-radio__label {
            color: #0d79ff;
          }
        }
      }
    }
  }
}
.main {
  position: relative;
  margin-bottom: 50px;
  min-height: calc(100vh - 66px - 162px - 196px);
  text-align: left;
  .lesson_box {
    .one_lesson {
      margin-right: 22px;
      margin-bottom: 25px;
      &:nth-child(5n) {
        margin: 0;
      }
    }
    .foot_page {
      margin-top: 30px;
      text-align: right;
      ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #0d79ff;
      }
    }
  }
}
</style>